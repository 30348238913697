import $ from './libs/jquery-3.1.1.min';
window.$ = $;
import './libs/jquery.easing';

import Ua from './libs/j_ua';
import jModal from './libs/j_modal_es';
import jSmoothScroll from './libs/j_smoothScroll_es';
import jScrollNav from './libs/j_scrollNav_es';
import jScrollEffect from './libs/j_scrollEffect_es';

////

///

const UA = new Ua();

///

// const J_MODAL = new jModal(
//     ".j-modal",
//     {
//         width: 768,
//         modalSpeed: 0,
//         bgOpacity: 1,
//         innerBgColor: "#fff",
//         innerBgPadding: 0,
//         addClass: "o-modal"
//     }
// );

///

const J_SCROLL = new jSmoothScroll(
    "a",
    {
    	easing: "easeOutQuint",
    	ignore: ".ignore",
    	posFix: Number($('#container').css('padding-top').replace('px', '')),
    	blank: true,
    }
);

///

const J_SCROLL_NAV = new jScrollNav(
    ".j-scrollnav",
    {
        boxElm: '.f-section',
        activeElm: 'li',
        posFix:  '50%',
        // edgeJudge: false

    }
);

///

const J_SCROLL_EFFECT = new jScrollEffect(
    ".j-anime",
    {
        ajustVal: 100
    }
);

///

const SHARE_SNS = () => {

    var siteURL = encodeURIComponent(location.href);

    $('.m-sns [data-sns]').on("click",function() {

        let type = $(this).attr("data-sns"),
            shareURL,
            shareType,
            shareOpt;

        if( type == "fb" ) { //FB

            shareURL = 'https://www.facebook.com/sharer/sharer.php?u='+siteURL;
            shareOpt = 'scrollbars=yes,location=yes,toolbar=no,width=670,height=340';

        } else if( type == "tw" ) { //TW

            shareURL = 'https://twitter.com/intent/tweet?url='+siteURL+'&text='+encodeURIComponent(document.title);
            shareOpt = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420';

        } else if( type == "line" ) { //Line

            if( UA.isSp || UA.isTab ){
                // アプリ版
                shareURL = 'http://line.me/R/msg/text/?'+siteURL;
            }else{
                // Web版
                shareURL = 'https://timeline.line.me/social-plugin/share?url='+siteURL;
            }
            shareOpt = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=500,height=550';
        }

        window.open( shareURL, null, shareOpt );

    });
}


///

const NAV_SQUARE = () => {

    var interval = 200;
    var timer;

    clearTimeout(timer);
    timer = setTimeout(function() {

        //処理
        $(window).on('scroll', function(){

            let posActive = $('#o-header_navi li.active').position().top;

            $('#o-header_navi .square').css('transform', 'translateY(' + posActive + 'px)');
        })

    }, interval);
}


///

const SET_LOADING = () => {

    $("html").addClass('is-loading-start');

    var transitionEnd = 'oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend',
    animationEnd = 'webkitAnimationEnd oanimationend msAnimationEnd animationend',
    $yourElement = $('#o-loading');

    $yourElement.on(transitionEnd, function() {
        $(".j-anime").addClass('is-loading-end');  // transition の終了後の処理
        $("html").addClass('is-loading-end');  // transition の終了後の処理
    });

}


///

const JUDGE_BROWSER = () => {

    if( UA.isIE || UA.isEdge ){
        $("html").addClass('is-browser-ms');
    }

}


///

const SET_SP_MENU = () => {

    $('#o-header .m-menu_btn').on('click', function(){
        $('html').toggleClass('is-menu-show');
    })

    $('#o-header_navi li a').on('click', function(){
        $('html').removeClass('is-menu-show');
    })

}


///

const J_POPUP = () => {

    $('.j-popup_detail').hide();

    $('.j-popup').on('click', function(){

        let index = $('.j-popup').index(this);
        $('#o-popup_add').html( $('.j-popup_detail').eq(index).html() );
        $('html').addClass('is-popup-show');

    })

    $('#o-popup').on('click', function(){
        PopupClose();
    })

    $('#o-popup .m-menu_btn').on('click', function(){
        PopupClose();
    })

    $('#o-popup_add').on('click', function(){
        return false;
    })

    const PopupClose = () => {
        $('#o-popup_add').html( );
        $('html').removeClass('is-popup-show');
    }

}

const SET_PAGETOP_POSITION = () => {


    let btnPagetop = $('#o-footer_pagetop');
    let posBottom;
    

    var interval = 200;
    var timer;

    clearTimeout(timer);
    timer = setTimeout(function() {

        //スクロールが200に達したらボタン表示
        $(window).on('load scroll', function(){

            //処理
            if( $('.m-menu_btn').is(':hidden') ){
                posBottom = 30;
            }else{
                posBottom = 10;
            }

            // 表示切り替え
        	if( $(this).scrollTop() > 200 ){
                btnPagetop.addClass('is-show');
            } else {
                btnPagetop.removeClass('is-show');
            }

            // ポジション設定
            let posDiff = $(this).scrollTop() + $(window).height() - $('#o-footer').position().top;

            if( posDiff > 0 ){
                btnPagetop.addClass('is-fixed').css('bottom', posDiff + posBottom);
            }else{
                btnPagetop.removeClass('is-fixed');
            }

        });

    }, interval);

}


///


const Init =  () => {

    SHARE_SNS();
    NAV_SQUARE();
    SET_LOADING();
    JUDGE_BROWSER();
    SET_SP_MENU();
    J_POPUP();
    SET_PAGETOP_POSITION();

}

Init();
