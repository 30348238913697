/*

License: Dentsu Isobar All Rights Reserved.

*/

export default class jScrollEffect {

    constructor(selector, option) {

        this.selector = selector;

        //option
        if(option == null) option = {};
        this.addClass = option.addClass ? option.addClass : "start";
        this.ajustVal = option.ajustVal ? option.ajustVal : 0;

        this.target = document.getElementsByClassName( selector.split(".")[1] );

        if( this.target ) {
            this.Init();
        }

    }


    /**
    **
    ** Init
    **
    **/
    Init() {

        var THAT = this;

        for (var i = 0; i < this.target.length; i++) {

            Func(i)

        }

        function Func(INDEX) {

            THAT.SetInitialClass(INDEX);
            THAT.StartEffect(INDEX);

            window.addEventListener('scroll', function() {
                THAT.StartEffect(INDEX);
            })
            window.addEventListener('resize', function() {
                THAT.StartEffect(INDEX);
            })

        }

    }

    /**
    **
    ** StartEffect
    **
    **/
    SetInitialClass(i) {

        var THAT = this;
        var data = this.target[i].getAttribute( "data-effecttarget" );


        if( data ) {

            this.dataTarget = document.querySelectorAll(data);

            for (var j = 0; j < this.dataTarget.length; j++) {

                this.dataTarget[j].classList.add( this.selector.split(".")[1] + "_transition" );
                EndFunc(j);

            }

            function EndFunc(j) {
                THAT.dataTarget[j].addEventListener('transitionend', function() {
                    THAT.dataTarget[j].classList.remove( THAT.selector.split(".")[1] + "_transition" );
                })
            }

        } else {

            this.target[i].classList.add( this.selector.split(".")[1] + "_transition" );

            this.target[i].addEventListener('transitionend', function() {
                THAT.target[i].classList.remove( THAT.selector.split(".")[1] + "_transition" );
            })

        }

    }


    /**
    **
    ** StartEffect
    **
    **/
    StartEffect(i) {

        var flag = false;

        var SCROLL_TOP = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        var WIN_HEIGHT = window.document.documentElement.clientHeight;
        var DOC_HEIGHT = document.documentElement.scrollHeight;

        var VIEW = SCROLL_TOP + WIN_HEIGHT;
        var OFFSET = this.GetOffset(this.target[i]).top + this.ajustVal;

        if( VIEW > OFFSET ) {

            if( !flag ) {
                this.target[i].classList.add( this.selector.split(".")[1] + "_" + this.addClass );
                flag = true;
            }

        } else {

            if( flag ) {
                flag = false;
            }

        }

        //scrollEnd
        if( SCROLL_TOP + WIN_HEIGHT >= DOC_HEIGHT - 200  ) {

            for (var j = 0; j < this.target.length; j++) {
                this.target[j].classList.add( this.selector.split(".")[1] + "_" + this.addClass );
            }

        }

    }


    /**
    **
    ** GetOffset
    **
    **/
    GetOffset(el) {

        var BOX = el.getBoundingClientRect();

        return {
            top: BOX.top + window.pageYOffset - document.documentElement.clientTop,
            left: BOX.left + window.pageXOffset - document.documentElement.clientLeft
        }

    }

}
